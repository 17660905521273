import styled, { css } from 'styled-components';
import {
  Styles,
  Heading,
  Button,
  Overlay,
  TextTransform,
  BasicTextSettings,
} from '../types/types';
import { getFontFamily } from '../helpers/getFontFamily';

export const PreviewBackground = styled.div<{
  backgroundColor?: string;
  customHeight?: string;
  customWidth?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${p => (p.customWidth ? p.customWidth : '100%')};
  min-width: ${p => (p.customWidth ? p.customWidth : '100%')};
  height: 100%;
  min-height: ${p => (p.customHeight ? p.customHeight : '700px')};
  background-color: ${p =>
    p.backgroundColor ? p.backgroundColor : 'transparent'};

  a {
    border: none;
    text-decoration: none !important;
  }

  &.bottom-left {
    justify-content: flex-end;
    align-items: flex-start;
  }

  &.bottom-right {
    justify-content: flex-end;
    align-items: flex-end;
  }

  &.bottom-center {
    justify-content: flex-end;
    align-items: center;
  }

  &.top-left {
    justify-content: flex-start;
    align-items: flex-start;
  }

  &.top-right {
    justify-content: flex-start;
    align-items: flex-end;
  }

  &.top-center {
    justify-content: flex-start;
    align-items: center;
  }

  &.center-left {
    align-items: flex-start;
  }

  &.center-right {
    align-items: flex-end;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    min-width: 100%;
    min-height: ${p =>
      p.customWidth === '50%' || p.customWidth === '65%' ? '54vh' : '350px'};
    justify-content: center !important;
    align-items: center !important;

    .quizkit-popup & {
      min-height: ${p =>
        p.customWidth === '50%' || p.customWidth === '65%' ? '54%' : '100%'};
    }
  }
`;

export const PreviewContainer = styled.div<{
  textAlignment?: string;
  loading?: boolean;
  maxWidth?: number;
  zIndex?: number;
  desktopPadding?: string;
  mobilePadding?: string;
}>`
  position: relative;
  max-width: ${p => p.maxWidth || 1060}px;
  margin: 0 auto;
  width: 100%;
  text-align: ${p => (p.textAlignment ? p.textAlignment : 'center')};
  visibility: ${p => (p.loading ? 'hidden' : 'visible')};
  z-index: ${p => p.zIndex || 3};
  padding: ${p => p.desktopPadding || '50px 50px 60px'};

  &.bottom-left,
  &.center-left,
  &.top-left {
    text-align: left;
  }

  &.bottom-right,
  &.center-right,
  &.top-right {
    text-align: right;
  }

  &.bottom-center,
  &.center-center,
  &.top-center {
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    padding: ${p => p.mobilePadding || '50px 20px 60px'};
    text-align: center !important;
  }
`;

export const PreviewGrid = styled.div.attrs<{
  textAlignment?: string;
  itemsPerRow?: string;
  width?: string;
  alignItems?: string;
  $type?: string;
}>(p => ({
  style: {
    justifyContent:
      // eslint-disable-next-line no-nested-ternary
      p.textAlignment === 'left'
        ? 'flex-start'
        : p.textAlignment === 'right'
        ? 'flex-end'
        : 'center',
    alignItems: p.alignItems ?? 'stretch',
  },
}))<{
  textAlignment?: string;
  itemsPerRow?: string;
  width?: string;
  alignItems?: string;
  $type?: string;
}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 0 !important;
  text-align: ${p => p.textAlignment || 'center'};
  max-width: ${p => (p.$type !== 'dot' ? p.width || '100' : '100')}%;

  @media screen and (max-width: 767px) {
    justify-content: center !important;
    max-width: 100%;
    padding: ${p => (p.$type === 'bar' ? '0 5%' : '0')};
  }

  ${p =>
    p.itemsPerRow === '1' && 'align-items: center; flex-direction: column;'}

  .swiper-container {
    width: 100%;
    overflow: unset;
    overflow-x: clip;
    z-index: 8;

    [id*='quiz-kit-results-product'] {
      height: 100%;
    }
  }
`;

export const PreviewText = styled.h1.attrs<Heading>(p => ({
  style: {
    fontStyle: p.fontStyle || 'normal',
    fontWeight: p.fontWeight || 700,
    width: p.maxWidth ? `${p.maxWidth}px` : '100%',
    margin: p.margin || '0 auto 20px',
    lineHeight: p.lineHeight || '1.2',
    fontSize: `${p.fontSize}px`,
    color: p.color || 'inherit',
    textTransform: p.textTransform || 'none',
    letterSpacing: p.letterSpacing ? `${p.letterSpacing}px` : 'initial',
  },
}))<Heading>`
  white-space: normal;
  max-width: 100%;
  text-align: ${p => p.textAlignment || 'inherit'};

  @media screen and (max-width: 767px) {
    font-size: ${p =>
      p.mobile?.fontSize
        ? `${p.mobile?.fontSize}px`
        : `${p.fontSize}px`} !important;
    font-weight: ${p =>
      p.mobile?.fontWeight
        ? p.mobile?.fontWeight
        : p.fontWeight || 700} !important;
    letter-spacing: ${p =>
      p.mobile?.letterSpacing
        ? `${p.mobile?.letterSpacing}px`
        : `${p.letterSpacing}px`} !important;
  }

  ${p => getFontFamily(p.fontFamily)}
`;

export const accessibilityStyles = css`
  &:focus-visible {
    outline: 2px solid #005bd3;
    outline-offset: 2px;
  }
`;

export const BaseButton = styled.button`
  ${accessibilityStyles}
`;

export const RadioBox = styled(BaseButton).attrs<{
  styles: Styles;
  layout: string;
}>(p => ({
  style: {
    width: 'auto',
    height: 'auto',
    minHeight:
      // eslint-disable-next-line
      p.layout === '1' ? '127px' : p.layout === '3' ? 'auto' : '60px',
    padding:
      // eslint-disable-next-line
      p.layout === '1' ? '30px 20px 20px' : p.layout === '3' ? '0' : '20px',
    flexDirection: p.layout === '1' ? 'column' : 'row-reverse',
    fontSize: `${p.styles.answerHeadingFontSize}px`,
    justifyContent:
      // eslint-disable-next-line
      p.layout === '1' ? 'inherit' : p.layout === '3' ? 'center' : 'flex-end',
    fontWeight: p.styles.answerHeadingFontWeight,
  },
}))<{ styles: Styles; layout: string }>`
  display: flex;
  min-width: ${p => (p.layout === '1' ? '127px' : '250px')};
  align-items: center;
  margin: 0 10px 20px;
  color: ${p => p.styles.answerHeadingTextColor};
  cursor: pointer;
  border: ${p =>
    p.layout === '3'
      ? 'none'
      : `${p.styles.borderWidth || 1}px solid ${p.styles.answersBorderColor}`};
  border-radius: ${p => p.styles.answersBorderRadius}px;
  background: ${p => (p.layout === '3' ? 'none' : p.styles.answersMainColor)};
  outline: none;
  transition: 0.25s;
  letter-spacing: ${p =>
    p.styles.answerHeadingLetterSpacing
      ? `${p.styles.answerHeadingLetterSpacing}px`
      : 'initial'};
  ${p => p.layout === '2' && 'text-align: left; span { margin-bottom: 2px; }'}

  &.focus-visible,
  &:focus-visible,
  &.active-class {
    ${p =>
      p.layout !== '3' &&
      `background: ${
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answerHeadingTextColor
          : 'transparent'
      };
    color: ${
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answersMainColor
        : p.styles.answerHeadingTextColor
    };
    border-color: ${
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answersBorderColor
        : p.styles.buttonMainColor
    };
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);`}

    span {
      border: 4px solid
      ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answersMainColor
          : p.styles.answerHeadingTextColor};
      background: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answerHeadingTextColor
          : 'transparent'};
    }
  }

  @media screen and (max-width: 767px) {
    min-width: 100% !important;
    ${p => (p.layout === '3' || p.layout === '2') && 'text-align: left;'}

    font-size: ${p =>
      p.styles.answerHeadingMobile?.fontSize
        ? `${p.styles.answerHeadingMobile?.fontSize}px`
        : `${p.styles.answerHeadingFontSize}px`} !important;
    font-weight: ${p =>
      p.styles.answerHeadingMobile?.fontWeight
        ? p.styles.answerHeadingMobile?.fontWeight
        : p.styles.answerHeadingFontWeight} !important;
    letter-spacing: ${p =>
      p.styles.answerHeadingMobile?.letterSpacing
        ? `${p.styles.answerHeadingMobile?.letterSpacing}px`
        : `${p.styles.answerHeadingLetterSpacing}px`} !important;
  }

  @media screen and (min-width: 768px) {
    &:hover {
      ${p =>
        p.layout !== '3' &&
        `background: ${
          p.styles.answersMainColor !== 'transparent'
            ? p.styles.answerHeadingTextColor
            : 'transparent'
        };
    color: ${
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answersMainColor
        : p.styles.answerHeadingTextColor
    };
    border-color: ${
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answersBorderColor
        : p.styles.buttonMainColor
    };
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);`}

      span {
        border: 4px solid
        ${p =>
          p.styles.answersMainColor !== 'transparent'
            ? p.styles.answersMainColor
            : p.styles.answerHeadingTextColor};
        background: ${p =>
          p.styles.answersMainColor !== 'transparent'
            ? p.styles.answerHeadingTextColor
            : 'transparent'};
      }
    }
  }

  ${p => getFontFamily(p.styles.bodyFont)}
`;

export const RadioBoxWrapper = styled.div<{
  width: string;
  textAlignment: string;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${p => p.textAlignment};
  width: ${p => p.width};
  max-width: 100%;
  ${p => p.width !== '100%' && `> button {min-width: 400px}`};

  @media screen and (max-width: 767px) {
    justify-content: center;

    > button {
      min-width: 250px;
    }
  }

  .swiper-slide {
    ${RadioBox} {
      min-width: auto;
      margin: 0;
    }
  }
`;

export const RadioCircle = styled.span<{ layout: string; styles: Styles }>`
  min-width: 15px;
  width: 15px;
  height: 15px;
  margin: ${p => (p.layout === '1' ? 'auto 0 0' : '0 15px 0 0')};
  border: 1px solid ${p => p.styles.answerHeadingTextColor};
  border-radius: 100%;
  background: ${p => p.styles.answersMainColor};
`;

export const PreviewImage = styled.div<{ margin: string; width?: string }>`
  display: block;
  width: ${p => p.width || '650'}px;
  max-width: 100%;
  margin: ${p => p.margin};

  @media screen and (max-width: 767px) {
    margin: 40px auto 30px;
  }
`;

export const ImageBoxGridItem = styled(BaseButton).attrs<{ styles: Styles }>({
  style: {
    height: 'auto',
    minHeight: '192px',
  },
})<{ styles: Styles }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 192px;
  max-width: 100%;
  padding: 30px 20px 20px;
  margin: 0 10px 20px !important;
  border: ${p =>
    `${p.styles.borderWidth || 1}px solid ${p.styles.answersBorderColor}`};
  border-radius: ${p => p.styles.answersBorderRadius}px;
  background: ${p => p.styles.answersMainColor};
  text-align: left;
  color: ${p => p.styles.answerHeadingTextColor};
  cursor: pointer;
  transition: 0.25s;

  h3 {
    word-break: break-word;
  }

  p {
    transition: 0.25s;
    word-break: break-word;
  }

  &.size-medium {
    width: 250px;
    min-height: 250px !important;
    padding: 40px 30px 30px;
  }

  &.size-large {
    width: 350px;
    min-height: 350px !important;
    padding: 50px 40px 40px;
  }

  &.text-alignment-center {
    align-items: center;
    text-align: center;
  }

  &.text-alignment-right {
    align-items: flex-end;
    text-align: right;
  }

  &.focus-visible,
  &.active-class {
    background: ${p =>
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answerHeadingTextColor
        : 'transparent'};
    color: ${p =>
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answersMainColor
        : p.styles.answerHeadingTextColor};
    border-color: ${p =>
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answersBorderColor
        : p.styles.buttonMainColor};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

    p {
      color: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answersMainColor
          : p.styles.answerDescriptionTextColor} !important;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;

    &.size-medium {
      width: 100%;
    }

    &.size-large {
      width: 100%;
    }
  }

  @media screen and (min-width: 768px) {
    &.focus-visible,
    &.active-class {
      transform: translateY(-5px);
    }

    &:hover {
      transform: translateY(-5px);
      background: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answerHeadingTextColor
          : 'transparent'};
      color: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answersMainColor
          : p.styles.answerHeadingTextColor};
      border-color: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answersBorderColor
          : p.styles.buttonMainColor};
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

      p {
        color: ${p =>
          p.styles.answersMainColor !== 'transparent'
            ? p.styles.answersMainColor
            : p.styles.answerDescriptionTextColor} !important;
      }
    }
  }

  ${p => getFontFamily(p.styles.bodyFont)}
`;

export const ImageWrapper = styled.div`
  min-width: 65px;
  margin-right: 30px;
  text-align: center;

  > div {
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    margin: 0 0 30px;
  }

  img {
    margin: 0;
  }
`;

export const PreviewSmallImage = styled.div<{ color?: string }>`
  position: relative;
  max-width: 100%;
  height: 55px;
  margin-bottom: 35px;
  color: ${p => p.color || 'inherit'};

  &.image-custom-size-medium {
    height: 75px;
  }

  &.image-custom-size-large,
  &.image-size-medium {
    height: 100px;
  }

  &.image-size-large {
    height: auto;
    width: 100%;

    svg {
      width: 100%;
    }
  }

  svg {
    width: auto;
    height: 100%;
  }
`;

export const IconTextGridItem = styled(BaseButton).attrs<{ styles: Styles }>({
  style: {
    minHeight: '134px',
    height: 'auto',
  },
})<{ styles: Styles }>`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 35px;
  margin: 0 10px 20px;
  border: ${p =>
    `${p.styles.borderWidth || 1}px solid ${p.styles.answersBorderColor}`};
  border-radius: ${p => p.styles.answersBorderRadius}px;
  background: ${p => p.styles.answersMainColor} !important;
  width: 450px;
  text-align: left;
  color: ${p => p.styles.answerHeadingTextColor} !important;
  cursor: pointer;
  outline: none;
  transition: 0.25s;

  &.focus-visible,
  &.active-class {
    background: ${p =>
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answerHeadingTextColor
        : 'transparent'} !important;
    color: ${p =>
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answersMainColor
        : p.styles.answerHeadingTextColor} !important;
    border-color: ${p =>
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answersBorderColor
        : p.styles.buttonMainColor};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

    p {
      color: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answersMainColor
          : p.styles.answerDescriptionTextColor} !important;
    }
  }

  @media screen and (min-width: 768px) {
    &.focus-visible,
    &.active-class {
      transform: translateY(-5px);
    }

    &:hover {
      transform: translateY(-5px);
      background: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answerHeadingTextColor
          : 'transparent'} !important;
      color: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answersMainColor
          : p.styles.answerHeadingTextColor} !important;
      border-color: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answersBorderColor
          : p.styles.buttonMainColor};
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

      p {
        color: ${p =>
          p.styles.answersMainColor !== 'transparent'
            ? p.styles.answersMainColor
            : p.styles.answerDescriptionTextColor} !important;
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  ${p => getFontFamily(p.styles.bodyFont)}
`;

export const ImageTextGridItem = styled(BaseButton).attrs<{
  width?: number;
  styles: Styles;
  removeImageBorder?: boolean;
}>({
  style: {
    height: 'auto',
    minHeight: '132px',
  },
})<{
  width?: number;
  styles: Styles;
  removeImageBorder?: boolean;
}>`
  width: 73px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  padding: 0;
  color: ${p => p.styles.answerHeadingTextColor};
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  background: none !important;
  transition: 0.25s;
  margin: 0 10px 25px;

  &.image-size-medium {
    width: 110px;
  }

  &.image-size-large {
    width: 130px;
  }

  &.range-width {
    width: ${p => p.width}px;
  }

  a,
  p {
    word-break: break-word;
  }

  > div {
    border-color: ${p => p.styles.answersBorderColor};
    ${p => p.removeImageBorder && 'border: none;'}
  }

  &.focus-visible,
  &.active-class {
    > div {
      border-color: ${p => p.styles.buttonMainColor};
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
  }

  @media screen and (min-width: 768px) {
    &.focus-visible,
    &.active-class {
      transform: translateY(-5px);
    }

    &:hover {
      transform: translateY(-5px);

      > div {
        border-color: ${p => p.styles.buttonMainColor};
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      }
    }
  }

  ${p => getFontFamily(p.styles.bodyFont)}
`;

export const ImageBox = styled.div<{
  width?: number;
  borderWidth?: number;
  shape?: string;
  colors?: string | null;
}>`
  position: relative;
  width: 73px;
  height: 73px;
  min-width: 73px;
  min-height: 73px;
  margin: 0 auto 20px;
  border-radius: ${p => (p.shape === 'square' ? 0 : '100%')};
  border: ${p => p.borderWidth || 3}px solid transparent;
  color: ${p => p.colors};

  .image-size-medium & {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
  }

  .image-size-large & {
    width: 130px;
    height: 130px;
    min-width: 130px;
    min-height: 130px;
  }

  .range-width & {
    width: ${p => p.width}px;
    height: ${p => p.width}px;
    min-width: ${p => p.width}px;
    min-height: ${p => p.width}px;
  }
`;

export const TextBox = styled(BaseButton).attrs<{ styles: Styles }>({
  style: {
    height: 'auto',
    minHeight: '268px',
  },
})<{ styles: Styles }>`
  width: 268px;
  margin: 0 10px 20px;
  padding: 50px 50px 50px 40px;
  max-width: 100%;
  text-align: left;
  color: ${p => p.styles.answerHeadingTextColor};
  cursor: pointer;
  border: ${p =>
    `${p.styles.borderWidth || 1}px solid ${p.styles.answersBorderColor}`};
  border-radius: ${p => p.styles.answersBorderRadius}px;
  background: ${p => p.styles.answersMainColor};
  outline: none;
  transition: 0.25s;

  h3 {
    word-break: break-word;
  }

  p {
    word-break: break-word;
    transition: 0.25s;
  }

  &.focus-visible,
  &.active-class {
    background: ${p =>
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answerHeadingTextColor
        : 'transparent'};
    color: ${p =>
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answersMainColor
        : p.styles.answerHeadingTextColor};
    border-color: ${p =>
      p.styles.answersMainColor !== 'transparent'
        ? p.styles.answersBorderColor
        : p.styles.buttonMainColor};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

    p {
      color: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answersMainColor
          : p.styles.answerDescriptionTextColor} !important;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    &.focus-visible,
    &.active-class {
      transform: translateY(-5px);
    }

    &:hover {
      transform: translateY(-5px);
      background: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answerHeadingTextColor
          : 'transparent'};
      color: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answersMainColor
          : p.styles.answerHeadingTextColor};
      border-color: ${p =>
        p.styles.answersMainColor !== 'transparent'
          ? p.styles.answersBorderColor
          : p.styles.buttonMainColor};
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

      p {
        color: ${p =>
          p.styles.answersMainColor !== 'transparent'
            ? p.styles.answersMainColor
            : p.styles.answerDescriptionTextColor} !important;
      }
    }
  }

  ${p => getFontFamily(p.styles.bodyFont)}
`;

export const AnswersValueWrapper = styled.div<{
  styles: Styles;
  $enableAnswer?: boolean;
}>`
  display: flex;
  justify-content: center;
  margin: 43px 0;
  flex-wrap: wrap;
  font-family: ${p => p.styles.bodyFont};
  font-size: ${p => p.styles.answerHeadingFontSize}px;
  font-weight: ${p => p.styles.answerHeadingFontWeight};
  color: ${p => p.styles.answerHeadingTextColor};
  min-height: 24px;
  line-height: 1.2;

  @media screen and (min-width: 768px) {
    display: ${p => (p.$enableAnswer ? 'flex' : 'none')};
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    font-weight: 600;
  }
`;

export const AnswerCurrentValue = styled.div`
  display: none;
  margin: 0 3px;

  &.active-class {
    display: block;

    &:first-child,
    &:last-child {
      display: none;
    }
  }
`;

export const AnswersMobileValue = styled.div<{
  $enableAnswer?: boolean;
  styles: Styles;
  width?: string;
  $type?: string;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  padding: 43px 0 0 0;
  font-family: ${p => p.styles.bodyFont};
  color: ${p => p.styles.answerHeadingTextColor};
  font-size: 20px;
  font-weight: 600;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    font-size: ${p => p.styles.answerHeadingFontSize}px;
    font-weight: ${p => p.styles.answerHeadingFontWeight};
    display: ${p => (p.$enableAnswer ? 'flex' : 'none')};
    max-width: ${p => (p.$type === 'bar' ? p.width : '100')}%;
  }

  @media screen and (max-width: 556px) {
    margin: 0 -10px;
  }
`;

export const AnswerLabel = styled.div<{
  $length: number;
}>`
  width: calc(100% / ${p => p.$length});

  @media screen and (max-width: 768px) {
    width: auto;
  }
`;

export const SliderItem = styled(BaseButton).attrs<{
  styles: Styles;
  answersCount: number;
  textAlignment?: string;
  typeRangeSlider: string;
  height: string;
  borderWidth: string;
  $enableAnswer?: boolean;
  multiSelection: boolean;
  $currentIndex: number;
}>({
  style: {
    height: 'auto',
    minHeight: 'auto',
  },
})<{
  styles: Styles;
  answersCount: number;
  textAlignment?: string;
  typeRangeSlider: string;
  height: string;
  borderWidth: string;
  $enableAnswer?: boolean;
  multiSelection: boolean;
  $currentIndex: number;
}>`
  position: relative;
  width: calc(100% / ${p => p.answersCount});
  text-align: center;
  float: left;
  font-size: ${p => p.styles.answerHeadingFontSize}px;
  font-weight: ${p => p.styles.answerHeadingFontWeight};
  color: ${p => p.styles.answerHeadingTextColor} !important;
  transition: 215ms;
  cursor: pointer;
  outline: none;
  background: none !important;
  border: none;
  padding: 0;
  letter-spacing: ${p =>
    p.styles.answerHeadingLetterSpacing
      ? `${p.styles.answerHeadingLetterSpacing}px`
      : 'initial'};

  &:first-child {
    ${p =>
      p.typeRangeSlider === 'dot' &&
      `margin-left: 
      ${
        p.textAlignment === 'left'
          ? `calc(-50% / ${p.answersCount} + 10px)`
          : '0'
      };`}
    &:before {
      ${p =>
        p.typeRangeSlider === 'bar' &&
        `border-left: ${p.borderWidth}px solid #000000;
        border-radius: ${p.height}px 0 0 ${p.height}px;`}
    }
  }

  &:last-child {
    &:before {
      ${p =>
        p.typeRangeSlider === 'bar' &&
        `border-radius: ${
          p.answersCount === 1
            ? `${p.height}px`
            : `0 ${p.height}px ${p.height}px 0`
        };`}
    }
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 20px 0 0 0;

    @media screen and (min-width: 769px) {
      display: ${p => (p.$enableAnswer ? 'none' : '-webkit-box')};
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &:before {
    content: '';
    position: relative;
    display: block;
    margin: 0 auto;
    z-index: 5;
    transition: background-color 215ms ease-in-out;
    ${p =>
      p.typeRangeSlider === 'dot' &&
      `width: 2px;
      background-color: #000;
      height: 46px;`}
    ${p =>
      p.typeRangeSlider === 'bar' &&
      `min-height: ${p.height}px;
      background-color: transparent;
      border-top: ${p.borderWidth}px solid #000;
      border-right: ${p.borderWidth}px solid #000;
      border-bottom: ${p.borderWidth}px solid #000;
      overflow: hidden;`}
  }

  &:after {
    ${p =>
      p.typeRangeSlider === 'dot' &&
      `content: '';
      position: absolute;
      top: 22px;
      left: -50%;
      width: 100%;
      height: 2px;
      background-color: #000;
      z-index: 0;`}
  }

  &.focus-visible,
  &.active-class {
    &:before {
      ${p => p.typeRangeSlider === 'bar' && `background-color: #3d3d3d;`}
    }

    div {
      ${p =>
        p.typeRangeSlider === 'dot' &&
        `background-color:
          ${
            p.styles.answersMainColor !== 'transparent'
              ? p.styles.answersMainColor
              : '#000'
          };
        border-color: ${p.styles.answersBorderColor || '#000'};`}
    }

    &:after {
      ${p =>
        p.typeRangeSlider === 'bar' &&
        `content: '';
        position: absolute;
        top: 0;
        z-index: 0;
        background-color: #3d3d3d;
        left: ${!p.multiSelection ? `calc(-100% * ${p.$currentIndex})` : '0'};
        width: ${!p.multiSelection ? `calc(100% * ${p.$currentIndex})` : '0'};
        height: ${!p.multiSelection ? `${p.height}px` : '0'};
        border-radius: ${
          !p.multiSelection ? `${p.height}px 0 0 ${p.height}px` : '0'
        };`}
    }
  }

  &:first-child:after {
    ${p => p.typeRangeSlider === 'dot' && `content: none;`}
  }

  @media screen and (min-width: 768px) {
    &:hover {
      div {
        ${p =>
          p.typeRangeSlider === 'dot' &&
          `background-color:
          ${
            p.styles.answersMainColor !== 'transparent'
              ? p.styles.answersMainColor
              : '#000'
          };
        border-color: ${p.styles.answersBorderColor || '#000'};`}
      }
    }
  }

  ${p => getFontFamily(p.styles.bodyFont)}
`;

export const SliderItemCircle = styled.div`
  display: block !important;
  position: absolute;
  top: 10px;
  left: calc(50% - 12px);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 30px;
  background-color: transparent;
  border: 2px solid transparent;
  z-index: 5;
  transition: all 215ms ease-in-out;
`;

export const SliderScaleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
`;

export const SliderScaleInner = styled.div`
  position: relative;
`;

export const SliderScaleInput = styled.input<{
  min: number;
  max: number;
  value: number;
  $height?: string;
}>`
  position: relative;
  z-index: 5;
  display: block;
  -webkit-appearance: none;
  height: ${p => (p.$height ? p.$height : '3')}px;
  background-color: transparent;
  border-radius: 5px;
  width: 100%;
  margin: 0 0 15px;
  cursor: pointer;
  outline: none;
  padding: 0;
  border: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    box-shadow: none;
    position: relative;
    z-index: 8;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    box-shadow: none;
    position: relative;
    z-index: 8;
  }
  &::-ms-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    box-shadow: none;
    position: relative;
    z-index: 8;
  }

  &::-webkit-slider-thumb:hover {
    background: transparent;
  }

  &::-moz-range-thumb:hover {
    background: transparent;
  }

  &::-ms-thumb:hover {
    background: transparent;
  }

  /* Input Track */
  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const SliderScaleValueTop = styled.div<{
  $enableAnswer?: boolean;
}>`
  @media screen and (min-width: 768px) {
    display: ${p => (p.$enableAnswer ? 'block' : 'none')};
  }

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

export const SliderScaleValueBottom = styled.div<{
  $enableAnswer?: boolean;
}>`
  @media screen and (min-width: 768px) {
    display: ${p => (p.$enableAnswer ? 'none' : 'block')};
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const SliderScaleValue = styled.div<{
  $length: number;
  $value: number;
  $enableAnswer?: boolean;
  styles: Styles;
}>`
  position: relative;
  padding: 0 2px;
  font-family: ${p => p.styles.bodyFont};
  font-size: ${p => p.styles.answerHeadingFontSize}px;
  font-weight: ${p => p.styles.answerHeadingFontWeight};
  color: ${p => p.styles.answerHeadingTextColor};
  text-align: center;
  width: calc(100% / ${p => (p.$length <= 4 ? 4 : p.$length)});
  transition: margin-left ease 0.3s;
  transform: translateX(-50%);
  margin-left: ${p => (100 / p.$length) * p.$value}%;
  min-height: 60px;

  @media screen and (min-width: 768px) {
    ${p =>
      p.$enableAnswer &&
      `width: 100%;
    transition: inherit;
    transform: inherit;
    margin-left: 0;
    margin-bottom: 43px;`}
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    transition: inherit;
    transform: inherit;
    margin-left: 0;
    margin-bottom: 43px;
    font-size: 20px;
    font-weight: 600;
  }
`;

export const ValueHidden = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
`;

export const SliderTrack = styled.div<{
  $height?: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  border-radius: 5px;
  height: ${p => (p.$height ? p.$height : '3')}px;
  background-color: #8a8a8a;
`;

export const SliderLine = styled.div<{
  $min: number;
  $max: number;
  $value: number;
  $height?: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  transition: width ease 0.3s;
  height: ${p => (p.$height ? p.$height : '3')}px;
  width: ${p => ((p.$value - p.$min) * 100) / (p.$max - p.$min)}%;
  background-color: #303030;
  border-radius: 5px;
  z-index: 2;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -8px;
    pointer-events: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #303030;
  }
`;

export const PreviewForm = styled.div<{ margin: string }>`
  width: 100%;
  max-width: 400px;
  margin: ${p => p.margin};
`;

export const PreviewDeclineSubscribe = styled.div.attrs<{ styles: Styles }>(
  p => ({
    style: {
      fontSize: `${p.styles.declineFontSize}px`,
      color: p.styles.declineColor,
      fontWeight: p.styles.declineFontWeight || 600,
    },
  }),
)<{ styles: Styles }>`
  display: block;
  margin: 20px auto 0;
  cursor: pointer;
  letter-spacing: ${p =>
    p.styles.declineLetterSpacing
      ? `${p.styles.declineLetterSpacing}px`
      : 'initial'};

  > svg[width='12px'] {
    margin: 0 10px 0 0;
  }

  ${p => getFontFamily(p.styles.bodyFont)}
`;

export const PreviewNavigation = styled.div<{
  styles: Styles;
  textAlignment: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${p => p.textAlignment};
  margin: 40px 0 0;
  max-width: 100%;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }

  ${p => getFontFamily(p.styles.bodyFont)}
`;

export const PreviewBtn = styled(BaseButton).attrs<{
  position?: string;
  color: string;
  removeRightMargin?: boolean;
  letterSpacing?: number;
  textTransform?: TextTransform;
}>(p => ({
  style: {
    height: 'auto',
    minHeight: 'auto',
    width: 'auto',
    fontSize: '14px',
    color: p.color,
    fontWeight: '600',
    background: 'none',
    border: 'none',
  },
}))<{
  position?: string;
  color?: string;
  removeRightMargin?: boolean;
  letterSpacing?: number;
  textTransform?: TextTransform;
}>`
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: ${p => (p.position === 'left' ? '0 150px 0 0' : '0')};
  transition: 0.25s;
  text-transform: ${p => p.textTransform ?? 'uppercase'};
  letter-spacing: ${p =>
    p.letterSpacing ? `${p.letterSpacing}px` : 'initial'};
  ${p => p.removeRightMargin && 'margin-right: 0;'}
  white-space: nowrap !important;

  @media screen and (max-width: 480px) {
    margin: ${p => (p.position === 'left' ? '0 100px 0 0' : '0')};
  }

  &.focus-visible,
  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  > svg {
    display: inline-block;
    vertical-align: baseline;
    margin: ${p => (p.position === 'right' ? '0 0 0 10px' : '0 10px 0 0')};
  }
`;

export const PreviewButton = styled(BaseButton).attrs<Button>(p => ({
  style: {
    // eslint-disable-next-line no-nested-ternary
    padding: p.size === 'large' ? '20px' : p.size === 'small' ? '13px' : '16px',
    borderRadius: p.borderRadius ? `${p.borderRadius}px` : 0,
    width: p.fullWidth ? '100%' : p.width ?? 'auto',
    minWidth:
      p.minWidth ??
      // eslint-disable-next-line no-nested-ternary
      (p.size === 'fullwidth'
        ? '100%'
        : // eslint-disable-next-line no-nested-ternary
        p.size === 'large'
        ? '260px'
        : p.size === 'small'
        ? '100px'
        : '160px'),
    maxWidth: p.maxWidth ?? 'none',
    fontSize: p.fontSize ? `${p.fontSize}px` : '16px',
    fontWeight: p.fontWeight || 600,
    margin: p.margin ? p.margin : '10px 0 0',
    color: p.textColor ? p.textColor : '#000000',
    background: p.kind !== 'outline' ? p.mainColor : 'transparent',
    border: `${p.borderWidth || 1}px solid ${
      p.borderColor ? p.borderColor : 'transparent'
    }`,
    lineHeight: '1.3',
    height: p.height || 'auto',
    minHeight: 'auto',
    letterSpacing: p.letterSpacing ? `${p.letterSpacing}px` : 'initial',
    textTransform: p.textTransform ?? 'uppercase',
  },
}))<Button>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  cursor: pointer;
  outline: none;
  transition: 0.25s;

  .active-class &,
  &.focus-visible,
  &:focus-visible {
    background-color: ${p => p.textColor} !important;
    color: ${p => p.mainColor} !important;
    border-color: ${p => p.mainColor} !important;
  }

  @media screen and (min-width: 767px) {
    &:hover {
      background-color: ${p => p.textColor} !important;
      color: ${p => p.mainColor} !important;
      border-color: ${p => p.mainColor} !important;
    }
  }

  &[disabled] {
    opacity: 0.5 !important;
    cursor: not-allowed;
    pointer-events: none;
  }

  div#quiz-kit-spinner {
    position: absolute;
    height: 100%;
    min-height: auto;

    svg {
      margin: 0;
      width: 30px;
      height: 30px;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: ${p =>
      p.mobile?.fontSize ? p.mobile?.fontSize : p.fontSize}px !important;
    font-weight: ${p =>
      p.mobile?.fontWeight
        ? p.mobile?.fontWeight
        : p.fontWeight || 700} !important;
    letter-spacing: ${p =>
      p.mobile?.letterSpacing
        ? p.mobile?.letterSpacing
        : p.letterSpacing}px !important;
  }

  ${p => getFontFamily(p.fontFamily)}
`;

export const AddToCartButtonWrapper = styled.div<{ $added: boolean }>`
  position: relative;
  overflow: hidden;

  > span:first-child {
    display: inline-block;
    transform: translateX(0);
    backface-visibility: hidden;
    transition: transform 0.3s;
    vertical-align: middle;
  }

  .add-button-checkmark,
  span:not(:first-child) {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translateX(50%) translateY(-50%);
    transition: transform 0.3s;
  }

  ${p =>
    p.$added &&
    `
    > span:first-child {
      color: transparent;
      transform: translateX(-100%);
      transition: transform 0s;
    }
    
    .add-button-checkmark,
    span:not(:first-child) {
      transform: translateX(-50%) translateY(-50%);
      transition: transform 0s;
    }

    .add-button-checkmark path {
      animation: checkmark-appear 0.3s;
      animation-fill-mode: forwards;
    }
  `}

  @keyframes checkmark-appear {
    from {
      stroke-dashoffset: 19.79;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`;

export const PreviewOverlay = styled.div.attrs<Overlay>(p => ({
  style: {
    display: 'block',
    backgroundColor: p.overlayColor ? p.overlayColor : 'transparent',
    opacity: p.overlayOpacity ? p.overlayOpacity / 100 : 0,
    margin: 0,
  },
}))<Overlay>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const SpinnerWrapper = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${p => p.backgroundColor};

  svg {
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px;

    circle {
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const ProductGridItem = styled.div<{
  $width: string;
  $productFullWidth?: boolean;
  $textAlignment?: string;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 10px 40px;
  width: ${p => p.$width};
  max-width: ${p =>
    p.$width === '100%' && !p.$productFullWidth ? '530px' : '100%'};
  text-align: ${p => (p.$width === '100%' ? 'left' : 'center')};
  align-items: center;
  justify-content: space-between;

  a {
    background: transparent !important;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    text-align: center;
    justify-content: space-between;

    &:last-child {
      padding-bottom: 0;
    }
  }

  ${p => p.$width === '100%' && 'flex-wrap: wrap;'}
  ${p =>
    p.$width === '100%' &&
    p.$textAlignment === 'center' &&
    'justify-content: space-between;'}
  ${p =>
    p.$width === '100%' &&
    p.$textAlignment === 'right' &&
    'justify-content: space-between;'}

  #quiz-kit-content .swiper-slide & {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ProductImageHolder = styled.a<{
  height?: string;
  hideLink?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: ${p => p.height}px;
  overflow: hidden;
  margin-bottom: 10px;
  pointer-events: ${p => (p.hideLink ? 'none' : 'auto')};
  padding-bottom: 0 !important;
  border: none !important;

  @media screen and (max-width: 767px) {
    height: auto;
  }

  picture,
  .quiz-kit-image-placeholder,
  .quiz-kit-image-container {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .quiz-kit-image-placeholder {
    aspect-ratio: 1;
    background-color: #e2e8f0;
  }

  img {
    max-width: none;
    width: auto;
    height: 100%;
    vertical-align: middle;
    background: transparent !important;
    margin: 0 !important;

    @media screen and (max-width: 767px) {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (min-width: 768px) {
    &.full-width {
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
      }
    }

    &.fixed-width {
      width: 280px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
      }
    }
  }
`;

export const SelectWrapper = styled.div<{
  width?: string;
  margin: string;
  textAlignment: string;
  showVariantsAsBoxes?: boolean;
}>`
  position: relative;
  width: ${p => (p.width ? p.width : '175px')};
  max-width: 100%;
  margin: ${p => p.margin};
  text-align: ${p => p.textAlignment ?? 'center'};
  //z-index: 3;

  @media screen and (max-width: 767px) {
    margin: 0 auto 10px;
    text-align: ${p => (p.showVariantsAsBoxes ? 'center' : 'left')};
  }

  .react-select__single-value {
    line-height: 1.1;
    margin: 0;
  }

  .react-select__placeholder {
    color: #dcdcdc;
  }

  .react-select__control {
    border-color: gainsboro;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      border-color: gainsboro;
    }
  }

  .react-select__menu {
    border: 1px solid gainsboro;
    border-top: none;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
  }

  .react-select__option {
    padding: 12px 16px;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f6;
    }
  }

  .react-select__input {
    opacity: 0 !important;
  }

  .react-select__input-container {
    padding: 0;
    margin: 0;

    &::after {
      display: none;
    }
  }

  .react-select__value-container {
    padding: 12px 6px 12px 16px;
  }

  .react-select__dropdown-indicator {
    color: inherit;
    padding: 11px 16px 11px 6px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu-list {
    padding: 0;
  }
`;

export const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

export const Select = styled.select<{
  styles: Styles;
  $colorsForBlock?: string;
}>`
  border: ${p => p.styles.borderWidth || 1}px solid rgb(239, 238, 236);
  -webkit-appearance: none;
  -moz-appearance: none;
  color: ${p =>
    p.$colorsForBlock ?? p.$colorsForBlock ?? p.styles.variantColor};
  width: 100%;
  max-width: 100%;
  display: block;
  background: #ffffff;
  padding: 10px 25px 10px 10px;
  height: auto;
  min-height: 41px;
  border-radius: ${p => p.styles.buttonBorderRadius || 0}px;
  letter-spacing: ${p =>
    p.styles.variantLetterSpacing
      ? `${p.styles.variantLetterSpacing}px`
      : 'initial'};
  margin: 0 auto;
  font-size: ${p => p.styles.variantFontSize}px;
  letter-spacing: ${p => p.styles.variantLetterSpacing}px;
  font-weight: ${p => p.styles.variantFontWeight};
  line-height: 1.3;
  ${p =>
    p.styles.backgroundColor !== '#ffffff' &&
    'border-color: transparent; border-width: 1px;'}
  ${p => getFontFamily(p.styles.bodyFont)}
  
  ${accessibilityStyles}
`;

export const VariantBoxes = styled.div`
  margin: 0 -4px;
`;

export const VariantBox = styled.div<{
  styles: Styles;
  $colorsForBlock?: string;
}>`
  line-height: 1.6;
  position: relative;
  display: inline-block;
  font-weight: ${p => p.styles.variantFontWeight};
  color: ${p =>
    p.$colorsForBlock ?? p.$colorsForBlock ?? p.styles.variantColor === '#ffff'
      ? '#000'
      : p.styles.variantColor};
  padding: 8.5px 16px;
  border-radius: ${p => p.styles.buttonBorderRadius || 0}px;
  background-color: #fff;
  border: ${p => p.styles.borderWidth || 1}px solid rgb(239, 238, 236);
  margin: 0 4px 8px;
  cursor: pointer;
  font-size: ${p => p.styles.variantFontSize}px;
  transition: 0.25s;
  letter-spacing: ${p =>
    p.styles.variantLetterSpacing
      ? `${p.styles.variantLetterSpacing}px`
      : 'initial'};
  ${p =>
    p.styles.backgroundColor !== '#ffffff' &&
    'border-color: transparent; border-width: 1px;'}

  &.active,
  &:focus-visible,
  &:hover {
    border-color: ${p =>
      p.styles.backgroundColor !== '#ffffff' &&
      p.styles.buttonBorderColor !== '#ffffff' &&
      p.styles.variantColor !== '#ffff'
        ? p.styles.buttonBorderColor
        : '#000000'};
  }

  ${accessibilityStyles}

  @media screen and (max-width: 767px) {
    font-size: ${p =>
      p.styles.variantMobile?.fontSize
        ? `${p.styles.variantMobile?.fontSize}px`
        : `${p.styles.variantFontSize}px`} !important;
    font-weight: ${p =>
      p.styles.variantMobile?.fontWeight
        ? p.styles.variantMobile?.fontWeight
        : p.styles.variantFontWeight || 700} !important;
    letter-spacing: ${p =>
      p.styles.variantMobile?.letterSpacing
        ? `${p.styles.variantMobile?.letterSpacing}px`
        : `${p.styles.variantLetterSpacing}px`} !important;
  }

  ${p => getFontFamily(p.styles.bodyFont)}
`;

export const AngleIcon = styled.img<{ borderWidth: number }>`
  position: absolute;
  right: ${p => p.borderWidth + 10}px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  margin: 0 !important;
`;

export const ContentBlock = styled.div<{
  minHeight?: string;
  backgroundColor?: string;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: ${p => (p.minHeight ? p.minHeight : '700px')};
  z-index: 0;
  background-color: ${p => p.backgroundColor};

  #quiz-kit-spinner {
    min-height: ${p => (p.minHeight ? p.minHeight : '700px')};
  }

  @media screen and (max-width: 767px) {
    min-height: 350px;

    #quiz-kit-spinner {
      min-height: 350px;
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  [role='button']:not(:focus-visible) {
    outline: none;
  }
`;

export const PreviewButtonWrapper = styled.div<{
  textAlignment?: string;
}>`
  width: 100%;
  text-align: ${p => p.textAlignment};
  pointer-events: all;

  @media screen and (max-width: 767px) {
    text-align: center;
    margin-top: 30px;
  }
`;

export const Watermark = styled.div<{ fontFamily?: string; color?: string }>`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  color: ${p => p.color || '#006fbb'};
  font-size: 13px;

  a {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
  }

  ${p => getFontFamily(p.fontFamily)}
`;

export const FieldBox = css<{ styles: Styles }>`
  width: 370px;
  height: 55px;
  display: initial !important;
  max-width: calc(100% - 20px);
  font-weight: ${p => p.styles.inputFontWeight || 600};
  margin: 0 10px;
  border: ${p =>
    `${p.styles.borderWidth || 1}px solid ${p.styles.inputBorderColor}`};
  border-radius: ${p => p.styles.inputBorderRadius}px;
  font-size: ${p => p.styles.inputFontSize || 16}px;
  color: ${p => p.styles.inputColor};
  background: #ffffff;
  padding: 10px 20px;
  outline: none;
  transition: 0.3s;
  letter-spacing: ${p =>
    p.styles.inputLetterSpacing
      ? `${p.styles.inputLetterSpacing}px`
      : 'initial'};

  &::placeholder {
    color: ${p => p.styles.inputColor};
    opacity: 0.5;
  }

  &:focus {
    box-shadow: 0 0 6px 3px rgba(96, 96, 96, 0.15);
  }

  ${accessibilityStyles}

  ${p => getFontFamily(p.styles.bodyFont)}
`;

export const InputBox = styled.input<{
  styles: Styles;
  textAlignment?: string;
}>`
  ${FieldBox};
  ${p => p.textAlignment && `text-align: ${p.textAlignment}; margin: 0;`}
`;

export const InputBoxWrapper = styled.div<{
  styles: Styles;
  phoneMask?: boolean;
  subscribeStep?: boolean;
}>`
  position: relative;

  .PhoneInputCountry {
    position: absolute;
    top: ${p => (p.subscribeStep ? 'calc(50% - 7px)' : 'calc(50% - 12px)')};
    left: ${p => (p.subscribeStep ? '20px' : '32px')};
  }

  .PhoneInputCountryIcon {
    width: 21px;
    height: 14px;
  }

  input {
    ${FieldBox};
    max-width: ${p => (p.subscribeStep ? '100%' : '350px')};
    text-align: left;
    margin: ${p => (p.phoneMask ? '0 auto 10px' : 0)};
    padding-left: 60px;
    padding-right: 60px;
  }
`;

export const TextareaBox = styled.textarea<{ styles: Styles }>`
  ${FieldBox};
  min-height: 100px;
  resize: none;
`;

export const PreviewDescription = styled.p.attrs<Heading>(p => ({
  style: {
    fontSize: `${p.fontSize}px`,
    color: p.color,
    fontWeight: p.fontWeight || 400,
    lineHeight: '1.4',
  },
}))<Heading>`
  width: 100%;
  margin: 5px auto 15px;
  white-space: normal;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  letter-spacing: ${p => p.letterSpacing || 0}px;
  letter-spacing: ${p =>
    p.letterSpacing ? `${p.letterSpacing}px` : 'initial'};
  ${p => getFontFamily(p.fontFamily)}

  @media screen and (max-width: 767px) {
    font-size: ${p =>
      p.mobile?.fontSize
        ? `${p.mobile?.fontSize}px`
        : `${p.fontSize}px`} !important;
    font-weight: ${p =>
      p.mobile?.fontWeight
        ? p.mobile?.fontWeight
        : p.fontWeight || 700} !important;
    letter-spacing: ${p =>
      p.mobile?.letterSpacing
        ? `${p.mobile?.letterSpacing}px`
        : `${p.letterSpacing}px`} !important;
  }
`;

export const PreviewDescriptionUnlimited = styled.div<Heading>`
  width: 100%;
  margin: 5px auto 15px;
  max-width: 100%;
  font-size: ${p => p.fontSize}px;
  color: ${p => p.color};
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: ${p =>
    p.letterSpacing ? `${p.letterSpacing}px` : 'initial'};
  ${p => getFontFamily(p.fontFamily)}

  @media screen and (max-width: 767px) {
    font-size: ${p =>
      p.mobile?.fontSize
        ? `${p.mobile?.fontSize}px`
        : `${p.fontSize}px`} !important;
    font-weight: ${p =>
      p.mobile?.fontWeight
        ? p.mobile?.fontWeight
        : p.fontWeight || 700} !important;
    letter-spacing: ${p =>
      p.mobile?.letterSpacing
        ? `${p.mobile?.letterSpacing}px`
        : `${p.letterSpacing}px`} !important;
  }
`;

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 7px;

  label {
    cursor: pointer;
    width: auto !important;
  }

  input {
    margin: 0;
    cursor: pointer;
  }
`;

export const RadioButtons = styled.div`
  margin-bottom: 20px;
`;

export const DiscountWrapper = styled.div<{
  margin?: string;
}>`
  margin: ${p => (p.margin ? p.margin : 0)};
`;

export const Discount = styled(BaseButton)<{
  resultDiscount?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  width?: string;
  onClick?: () => void;
}>`
  display: inline-flex;
  gap: 16px;
  align-items: center;
  position: relative;
  min-width: 335px;
  width: ${p => p.width};
  text-align: center;
  padding: ${p => (p.resultDiscount ? '0 27px 0 16px' : '0 15px')};
  border: 1px solid ${p => (p.borderColor ? p.borderColor : '#979797')};
  background: ${p => (p.backgroundColor ? p.backgroundColor : '#fafafa')};
  letter-spacing: 3px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    min-width: 100%;
  }
  img {
    margin: 0 !important;
  }

  h2 {
    flex: 1;
    padding: 15px 0;

    span {
      position: relative;
      right: -6px;
      bottom: 2px;
      vertical-align: middle;
    }
  }
`;

export const SecondaryButton = styled(BaseButton).attrs<Button>(p => ({
  style: {
    width: 'auto',
    height: 'auto',
    minHeight: 'auto',
    fontSize: p.fontSize ? p.fontSize : '10px',
    color: p.textColor ? p.textColor : '#000000',
    fontStyle: p.fontStyle ? p.fontStyle : 'normal',
    fontWeight: p.fontWeight || 600,
    border: 'none',
    borderBottom: `${p.borderWidth || 1}px solid ${p.mainColor}`,
    background: 'none',
    lineHeight: '1.3',
    borderRadius: '0',
    letterSpacing: p.letterSpacing ? `${p.letterSpacing}px` : 'initial',
  },
}))<Button>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: ${p => (p.margin ? p.margin : '10px 0 0')};
  text-transform: ${p => p.textTransform ?? 'uppercase'};
  padding: 6px 10px;
  max-width: 100%;
  min-width: 100px;
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: 0.8;
  }

  ${p => getFontFamily(p.fontFamily)}
`;

export const DayPickerInputWrapper = styled.div<{ styles: Styles }>`
  position: relative;
  width: 100%;
  max-width: 370px;
  margin-bottom: 10px;

  svg {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    z-index: 1;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: rgba(0, 0, 0, 0.1);
    background-color: transparent;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-bottom-color: #fff;
    background: transparent;
  }

  .react-datepicker-popper {
    width: 100%;
    pointer-events: none;

    & > div {
      pointer-events: none;
    }
  }

  .react-datepicker__header {
    background-color: transparent;
    border-bottom-color: rgba(0, 0, 0, 0.1);
    padding: 8px 0 0 0;

    & > div {
      gap: 8px;
    }

    select {
      border-color: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 2px 24px 3px 4px;

      background-size: 8px;
      background-repeat: no-repeat;
      background-position: calc(100% - 5px) 50%;
      background-image: url("data:image/svg+xml,%3Csvg fill='%238b9898' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 330 330' xml:space='preserve'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath id='XMLID_102_' d='M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
      margin: 0;

      appearance: none;
      -moz-appearance: none;
      -ms-progress-appearance: unset;
      -webkit-appearance: none;
    }

    button {
      position: relative;
      border: none;
      background-color: transparent;
      cursor: pointer;
      text-indent: -9999px;
      font-size: 0;
      padding: 0;
      margin: 0;

      &:first-child:before,
      &:last-child:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        text-indent: initial;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='%23000000' version='1.1' id='Layer_1' viewBox='0 0 330 330' xml:space='preserve'%3E%3Cpath id='XMLID_92_' d='M111.213,165.004L250.607,25.607c5.858-5.858,5.858-15.355,0-21.213c-5.858-5.858-15.355-5.858-21.213,0.001 l-150,150.004C76.58,157.211,75,161.026,75,165.004c0,3.979,1.581,7.794,4.394,10.607l150,149.996 C232.322,328.536,236.161,330,240,330s7.678-1.464,10.607-4.394c5.858-5.858,5.858-15.355,0-21.213L111.213,165.004z'/%3E%3C/svg%3E");
      }

      &:last-child {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }

  .react-datepicker__day--outside-month {
    color: #8b9898;
  }

  .react-datepicker__day-name {
    color: #8b9898;
    font-weight: 600;
  }

  .react-datepicker {
    font-size: 10px;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.1);
    font-family: ${p => p.styles.bodyFont};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    pointer-events: auto;

    select {
      font-family: ${p => p.styles.headingFont};
      outline: none;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 30px;
    padding: 9px 10px 11px;
    line-height: 1;
    margin: 0;
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }
  }

  .react-datepicker__day--today,
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    font-weight: 400;
  }

  .react-datepicker__month {
    margin: 0.4rem !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
    margin: 0;

    input {
      ${FieldBox};
      margin: 0;
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #4a90e2;
    color: #fff;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    padding: 0 !important;
    height: 30px;
    line-height: 30px;
  }
  .react-datepicker__day-names,
  .react-datepicker__week {
    margin: 0 !important;
  }
`;

export const PreviewContentWrapper = styled.div<{
  customHeight?: string;
  flexDirection: string;
}>`
  min-height: ${p => (p.customHeight ? p.customHeight : '700px')};
  position: relative;
  display: flex;
  flex-direction: ${p => p.flexDirection};
  width: 100%;
  height: 100%;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    min-height: 350px;
  }
`;

export const PreviewContentImage = styled.div<{
  customHeight?: string;
}>`
  position: relative;
  width: 50%;
  min-height: ${p => (p.customHeight ? p.customHeight : '700px')};
  margin: 0 !important;

  @media screen and (max-width: 767px) {
    width: 100%;
    min-height: 46vh;

    .quizkit-popup & {
      min-height: 46%;
    }
  }
`;

export const IconTextContent = styled.div`
  @media screen and (max-width: 767px) {
    text-align: center;
  }
`;

export const PreviewComparedPrice = styled.span<{
  fontSize: string;
  margin?: string;
  mobile?: BasicTextSettings;
}>`
  text-decoration: line-through;
  opacity: 0.6;
  margin: ${p => p.margin ?? '0'};
  font-size: ${p => parseInt(p.fontSize, 10) - 1}px;

  @media screen and (max-width: 767px) {
    font-size: ${p =>
      p.mobile?.fontSize
        ? `${p.mobile?.fontSize}px`
        : `${p.fontSize}px`} !important;
  }
`;

export const PreviewPrice = styled.span`
  color: inherit;
  word-wrap: normal;
`;

export const PreviewMoneySaved = styled.div<{
  styles: Styles;
  alignment?: 'left' | 'right';
}>`
  position: absolute;
  top: 8px;
  ${p => p.alignment ?? 'right'}: 20px;
  display: inline-block;
  padding: 3px 7px;
  font-size: 14px;
  color: #000000;
  background-color: #fbfafa;
  border-radius: ${p => p.styles.buttonBorderRadius}px;
  ${p => getFontFamily(p.styles.bodyFont)}
  z-index: 2;
`;

export const PreviewContent = styled.div<{ styles: Styles }>`
  text-align: ${p =>
    p.styles?.contentImgPosition === 'background' ? 'center' : 'inherit'};
  background: ${p =>
    p.styles?.contentImgPosition === 'background'
      ? p.styles.backgroundColor
      : 'transparent'};
  max-width: ${p =>
    p.styles?.contentImgPosition === 'background' ? '660px' : '100%'};
  padding: ${p =>
    p.styles?.contentImgPosition === 'background' ? '30px' : '0'};
  margin: 0 auto;

  svg {
    display: initial;
    margin-bottom: 20px;
  }
`;

export const ProductContent = styled.div<{
  $width: string;
  $productFullWidth?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: ${p =>
    // eslint-disable-next-line
    p.$width === '100%' && p.$productFullWidth
      ? 'calc(100% - 310px)'
      : p.$width === '100%'
      ? '200px'
      : '290px'};
  width: 100%;
  margin: ${p => (p.$width === '100%' ? '0 auto 0 30px' : '0 auto')};

  #quiz-kit-content .swiper-slide & {
    max-width: 100%;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
    margin: 0 auto;
  }
`;

export const ProductHeader = styled.div``;
export const ProductFooter = styled.div``;

export const QuantitySelector = styled.div`
  position: relative;
  max-width: 100px;
  margin: 10px 0 0;
  z-index: 0;

  svg {
    display: inline-block;
    width: 12px;
    height: 12px;
    color: #545454;
    vertical-align: middle;
    fill: currentColor;
  }
`;

export const QuantityAdjust = styled.span`
  position: absolute;
  top: 0;
  height: 100%;
  width: 40%;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  margin: 0 !important;

  &:hover {
    opacity: 0.7;
  }

  &.qty__minus {
    left: 0;
    justify-content: flex-start;
    padding-left: 10px;
  }

  &.qty__plus {
    right: 0;
    justify-content: flex-end;
    padding-right: 10px;
  }

  ${accessibilityStyles}
`;

export const QuantityInput = styled.input.attrs<{
  fontFamily: string;
  borderRadius: string;
  // eslint-disable-next-line
}>(p => ({
  style: {
    fontSize: '14px',
    color: '#545454',
    fontWeight: '400',
    border: '1px solid #efeeec',
    background: '#ffffff',
    padding: '12px 16px',
    margin: '0',
    lineHeight: '1',
    height: '41px',
    textIndent: 'unset',
  },
}))<{
  fontFamily: string;
  borderRadius: string;
}>`
  width: 100%;
  text-align: center;
  border-radius: ${p => p.borderRadius}px;
  max-width: 100%;
  appearance: none;
  -moz-appearance: textfield;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
    border-color: #545454;
  }

  ${accessibilityStyles}

  ${p => getFontFamily(p.fontFamily)}
`;

export const QuantityWrapper = styled.div<{
  margin: string;
  justifyContent: string;
  maxWidth?: string;
  width?: string;
}>`
  display: flex;
  justify-content: ${p => p.justifyContent};
  flex-wrap: wrap;
  column-gap: 8px;
  max-width: ${p => p.maxWidth ?? '100%'};
  width: ${p => p.width ?? 'initial'};
  margin: ${p => p.margin};

  @media screen and (max-width: 767px) {
    margin-left: auto;
    justify-content: center;
  }
`;

export const Popup = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  width: 768px;
  max-width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s linear 0.5s;
  text-align: center;
  transform: translate3d(0, -100%, 0);
  max-height: 100vh;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  &.popup-visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s linear 0s;
    transform: translate3d(0, 0, 0);
  }
`;

export const PopupClose = styled.span<{ mainColor: string; textColor: string }>`
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  background: ${p => p.mainColor || '#000'};
  z-index: 99;
  cursor: pointer;
  transition: 0.3s;
  outline: 0;
  border: 1px solid ${p => p.mainColor || '#000'};

  &:after,
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: ' ';
    height: 24px;
    width: 2px;
    background: ${p => p.textColor || '#fff'};
    transition: 0.3s;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    background: ${p => p.textColor || '#fff'};

    &:after,
    &:before {
      background: ${p => p.mainColor || '#000'};
    }
  }

  svg {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    fill: currentColor;
    stroke: #333;
    pointer-events: none;
  }
`;

export const PopupContainer = styled.div`
  display: block;
  max-height: 480px;
  overflow-y: auto;
`;

export const PopupProduct = styled.div`
  display: flex;
  align-items: stretch;
  background: #fff;
  border-left: 1px solid #efeeec;
  border-bottom: 1px solid #efeeec;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const PopupImageWrapper = styled.div`
  width: 50%;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const PopupImage = styled.div<{ src: string }>`
  display: block !important;
  height: 100%;
  background: url(${p => p.src}) no-repeat center/cover;
`;

export const PopupContent = styled.div`
  width: 50%;
  padding: 50px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const PopupButtonsWrapper = styled.div`
  margin-top: 20px;
`;

export const DropzoneWrapper = styled.div<{
  margin: string;
  fontFamily: string;
}>`
  width: 500px;
  max-width: 100%;
  margin: ${p => p.margin};

  @media screen and (max-width: 767px) {
    margin: 0 auto;
  }

  ${p => getFontFamily(p.fontFamily)}
`;

export const DropzoneContent = styled.div`
  padding: 45px 15px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #000000;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  ${accessibilityStyles}
`;

export const ThumbnailsContainer = styled.div<{ justifyContent: string }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${p => p.justifyContent};
  align-items: center;
  margin: 16px -5px 0;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`;

export const Thumbnail = styled.div<{
  imageUrl: string;
}>`
  display: block !important;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin: 0 5px 8px;
  width: calc(33% - 10px);
  height: 153px;
  padding: 5px 10px 10px;
  box-sizing: border-box;
  background: url(${p => p.imageUrl}) no-repeat center/cover;

  @media screen and (max-width: 767px) {
    height: 103px;
  }
`;

export const ThumbnailContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const ThumbnailFileName = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-break: anywhere;
  font-size: 14px;
  margin: 0;
`;

export const DropzoneError = styled.p<{
  fontFamily: string;
}>`
  font-size: 14px;
  color: #e6391a;
  margin-top: 10px;
  ${p => getFontFamily(p.fontFamily)}
`;

export const DropzoneText = styled.p<{
  fontFamily: string;
}>`
  font-size: 14px;
  margin: 0;
  ${p => getFontFamily(p.fontFamily)}
`;

export const UploadSpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  #quiz-kit-spinner {
    min-height: 100%;
  }
`;

export const UploadSpinnerOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background: white;
`;

export const Thumbnails = styled.div`
  display: flex;
  width: fit-content;
`;

export const ThumbnailProduct = styled.div<{
  borderColor: string;
  borderWidth?: number;
  size?: number;
}>`
  position: relative;
  width: ${p => p.size ?? 52}px;
  margin: 1px 8px 1px 1px;
  border: ${p => p.borderWidth ?? 2}px solid ${p => p.borderColor};
  border-radius: 1px;
  background-color: rgb(244, 246, 248);
  transform: translateZ(0);
  overflow: hidden;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  picture,
  .quiz-kit-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    object-fit: cover;
  }

  ${accessibilityStyles}
`;

export const ThumbnailsWrapperProduct = styled.div<{
  justifyContent: string;
  margin?: string;
  maxWidth?: string;
}>`
  display: flex;
  justify-content: ${p => p.justifyContent};
  width: 100%;
  overflow-x: scroll;
  max-width: ${p => (p.maxWidth ? p.maxWidth : '280px')};
  margin: ${p => p.margin ?? '0'};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ResponsiveBackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  margin: 0 !important;

  picture,
  .quiz-kit-image-container {
    width: 100%;
    height: 100%;
    border-radius: inherit;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
      margin: 0 !important;
    }
  }
`;

export const ResponsiveImage = styled.div<{
  aspectRatio?: number | string | null;
}>`
  width: 100%;
  height: inherit;
  border-radius: inherit;

  picture,
  .quiz-kit-image-container {
    display: block;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    aspect-ratio: ${p => (p.aspectRatio ? p.aspectRatio : 'initial')};
  }

  img {
    display: block;
    width: ${p => (p.aspectRatio ? '100%' : 'auto')};
    max-width: 100% !important;
    height: inherit !important;
    border-radius: inherit !important;
    margin: 0 auto !important;
    aspect-ratio: ${p => (p.aspectRatio ? p.aspectRatio : 'initial')};
    border: none;
    box-shadow: none;
  }
`;

export const SubmitForm = styled.form`
  max-width: 100%;
`;

export const ImageIcon = styled.div<{ color?: string }>`
  padding: 10%;
  width: 100%;
  height: 100%;
  color: ${p => p.color || 'inherit'};

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 700px;

  @media screen and (max-width: 767px) {
    min-height: 350px;
  }
`;

export const SkeletonBoxes = styled.div`
  display: flex;
  margin-top: 50px;

  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
`;

export const SkeletonBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;

  @media screen and (max-width: 1300px) {
    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    &:nth-child(2) {
      display: none;
    }
  }
`;

export const SkeletonLineAnimation = styled.div<{
  color?: string;
  image?: string;
}>`
  @keyframes animation-Skeleton-line {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }

  position: absolute;
  top: 0;
  bottom: 0;
  background-color: ${p => p.color || '#f2f2f2'};
  background-image: ${p =>
    p.image || 'linear-gradient(90deg, #f2f2f2, #f6f6f6, #f2f2f2)'};
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: animation-Skeleton-line 1.6s ease-in-out infinite;
`;

export const SkeletonLine = styled.div<{
  width?: number;
  height?: number;
  color?: string;
  marginBottom?: number;
}>`
  position: relative;
  width: ${p => p.width || 200}px;
  max-width: 100%;
  height: ${p => p.height || 10}px;
  border-radius: 5px;
  background-color: ${p => p.color || '#f2f2f2'};
  margin-bottom: ${p => p.marginBottom || 10}px;

  @media screen and (max-width: 767px) {
    max-height: 156px;
    max-width: 335px;
  }
`;

export const SubscribeCheckboxWrapper = styled.div<{
  justifyContent: string;
  marginBottom?: string;
  padding?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: ${p => p.justifyContent};
  margin: 15px auto ${p => p.marginBottom || '-10px'};
  cursor: pointer;
  padding: ${p => p.padding || 0};

  input {
    -webkit-appearance: checkbox;
    margin: 0 10px 0 0;
    cursor: pointer;

    ${accessibilityStyles}
  }

  span {
    width: auto !important;
  }
`;

export const TooltipWrapper = styled.div<{ margin: string }>`
  font-family: Cartograph-Mono, sans-serif;
  font-size: 14px;
  position: relative;
  width: 34px;
  cursor: pointer;
  margin: ${p => p.margin};
  z-index: 1;

  @media screen and (max-width: 767px) {
    margin: 30px auto;
  }

  &:hover div {
    opacity: 1;
    z-index: 1;
    visibility: visible;
    transition: all 0.3s ease;
  }
`;

export const TooltipIcon = styled.div`
  font-size: 14px;
`;

export const TooltipInner = styled.div<{
  position: 'top' | 'bottom';
  left: string;
  right: string;
  transform: string;
}>`
  width: 300px;
  background: #ffffff;
  position: absolute;
  left: ${p => p.left};
  right: ${p => p.right};
  transform: ${p => p.transform};
  bottom: ${p => (p.position === 'bottom' ? 'calc(100% + -5px)' : 'auto')};
  top: ${p => (p.position === 'bottom' ? 'auto' : 'calc(100% + -10px)')};
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0 5px rgb(0 0 0 / 15%);
  padding: 15px 15px 20px;
  max-height: 195px;
  overflow: auto;

  @media screen and (max-width: 767px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
`;

export const ProgressBarWrapper = styled.div<{
  top: number;
  padding?: string;
}>`
  position: absolute;
  top: ${p => p.top}px;
  width: 100%;
  padding: ${p => p.padding || '0 55px'};
  z-index: 6;

  @media screen and (max-width: 767px) {
    padding: ${p => p.padding || '0 20px'};
  }
`;

export const ProgressBarContainer = styled.div<{ maxWidth?: number }>`
  max-width: ${p => (p.maxWidth ? `${p.maxWidth}px` : '100%')};
  margin: 0 auto;
  text-align: right;
`;

export const CirclesBarContent = styled.div<{ color: string }>`
  background: ${p => p.color};
  height: 2px;
`;

export const CirclesBarList = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CirclesBarItem = styled.span<{
  size: number;
  active?: boolean;
  color: string;
  activeColor: string;
}>`
  position: relative;

  &:after {
    background: ${p => (p.active ? p.activeColor : p.color)};
    border: 1px solid ${p => (p.active ? p.activeColor : p.color)};
    border-radius: 50%;
    content: ' ';
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    left: -${p => p.size / 2 - 1}px;
    margin: auto;
    position: absolute;
    right: 0;
    top: -${p => p.size / 2 - 1}px;
    transition: background 0.5s;
  }
`;

export const CirclesBarProgress = styled.span<{ width: number; color: string }>`
  display: block;
  background: ${p => p.color};
  height: 2px;
  transition: width 0.45s;
  width: ${p => p.width}%;
  max-width: 100%;
  margin: 0 !important;
`;

export const DashesBarList = styled.div<{ color: string; size: number }>`
  border: 1px solid ${p => p.color};
  border-radius: ${p => p.size}px;
  display: flex;
  height: ${p => p.size}px;
  width: 100%;
  padding: 2px;
`;

export const DashesBarListItem = styled.span<{
  width: number;
  size: number;
  active?: boolean;
  color: string;
  activeColor: string;
}>`
  width: ${p => p.width}%;
  height: 100%;
  margin: 3px;
  background: ${p => (p.active ? p.activeColor : p.color)};
  border: 1px solid ${p => (p.active ? p.activeColor : p.color)};
  border-radius: ${p => p.size}px;
  align-self: center;
  transition: all 0.5s;
`;

export const BoxesBarList = styled.div`
  width: 100%;
  display: flex;
  -webkit-box-align: baseline;
  align-items: baseline;
`;

export const BoxesBarListItem = styled.div<{
  width: number;
  size: number;
  active?: boolean;
  color: string;
  textColor: string;
  activeColor: string;
}>`
  display: flex;
  align-items: center;
  width: ${p => p.width}%;
  background: ${p => (p.active ? p.activeColor : p.color)};
  border: 1px solid ${p => p.textColor};
  border-left: unset;
  text-align: center;
  height: 50px;
  transition: all 0.5s;

  &:last-child {
    border-right: unset;
  }
`;

export const SelectBoxWrapper = styled.div<{ styles: Styles }>`
  width: 320px;
  padding: 0 10px;

  [class*='multiValue'] {
    > div {
      color: ${p => p.styles.inputColor};
    }
  }

  * {
    font-weight: ${p => p.styles.inputFontWeight};
    color: ${p => p.styles.inputColor};
    ${p => getFontFamily(p.styles.bodyFont)}
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
  }
`;

export const GdprCheckboxWrapper = styled.div`
  width: 100%;
`;

export const PreviewButtonLink = styled.a.attrs<Button>(p => ({
  style: {
    width: p.fullWidth ? '100%' : 'auto',
    height: p.height || 'auto',
    // eslint-disable-next-line no-nested-ternary
    padding: p.size === 'large' ? '20px' : p.size === 'small' ? '13px' : '16px',
    borderRadius: p.borderRadius ? `${p.borderRadius}px` : 0,
    minWidth:
      // eslint-disable-next-line no-nested-ternary
      p.size === 'large' ? '260px' : p.size === 'small' ? '100px' : '160px',
    fontSize: p.fontSize ? `${p.fontSize}px` : '16px',
    letterSpacing: p.letterSpacing ? `${p.letterSpacing}px` : '0',
    fontWeight: p.fontWeight || 600,
    textTransform: p.textTransform ?? 'uppercase',
  },
}))<Button>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: ${p => (p.margin ? p.margin : '10px 0 0')};
  font-weight: 600;
  max-width: 100%;
  cursor: pointer;
  outline: none;
  color: ${p => (p.textColor ? p.textColor : '#000000')} !important;
  background: ${p =>
    p.kind !== 'outline' ? p.mainColor : 'transparent'} !important;
  border: ${p => (p.borderWidth ? `${p.borderWidth}px` : '1px')} solid
    ${p => (p.borderColor ? p.borderColor : 'transparent')} !important;
  transition: 0.25s;
  text-decoration: none !important;

  .active &,
  &:hover {
    background: ${p => p.textColor} !important;
    color: ${p => p.mainColor} !important;
    border-color: ${p => p.mainColor} !important;
  }

  ${accessibilityStyles}

  ${p => getFontFamily(p.fontFamily)}
`;

export const FormWrapper = styled.div<{ textAlignment?: string }>`
  width: 370px;
  max-width: 100%;

  > input {
    margin: 0 0 10px;
    text-align: ${p => p.textAlignment || 'left'};
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .DayPickerInput input,
  .PhoneInput input {
    text-align: ${p => p.textAlignment || 'left'};
  }

  > textarea {
    margin: 0 10px 6px;
    text-align: ${p => p.textAlignment || 'left'};
  }
`;

export const SwiperWrapper = styled.div`
  width: 100%;

  ${ImageTextGridItem}, ${IconTextGridItem}, ${ImageBoxGridItem}, ${TextBox} {
    margin: 5px 0 0;
  }
`;
