import React, { ReactNode } from 'react';
import { Article } from '../components/resultLayouts/types';
import { VariantsOption } from '../components/resultLayouts/layouts/types';

export const initialState = {
  id: 0,
  quiz: {
    title: {
      text: '',
      fontSize: '40',
      fontFamily: 'Old Standard TT',
      color: '#000000',
    },
    description: {
      text: '',
      fontSize: '16',
      fontFamily: 'Arapey',
      color: '#000000',
    },
    button: {
      text: 'Start Quiz',
      fontSize: '13',
      fontFamily: 'Montserrat',
      textColor: '#ffffff',
      mainColor: '#000000',
      style: 'fill',
      borderRadius: '30',
      size: 'medium',
      fontWeight: 600,
    },
    background: {
      type: 'solid',
      image: null,
      backgroundColor: '#d6e8f8',
      overlayColor: '#000000',
      overlayOpacity: 0,
    },
    questionBackground: {
      backgroundColor: '#d6e8f8',
    },
    questions: [
      {
        id: 1,
        name: 'Question 1',
        description: '',
        type: '',
        answers: [
          {
            id: 1,
            name: 'Answer 1',
            image: null,
          },
        ],
        multiSelection: true,
      },
    ],
    collectEmail: false,
    discountTitle: null,
    discountCode: null,
    conditionalLogic: false,
  },
  step: 'initialState',
  questionNumber: 0,
  chosenAnswers: {},
  quizKey: '',
  featuresList: [],
  inputValues: {},
  inputErrors: {},
  recharge: false,
  redirectUrl: '',
  quizStarted: false,
  questionsPath: [],
  moneyFormat: '',
  predefinedQuantities: {},
  predefinedValues: {},
  personalizationSection: false,
  nativeShopifySubscriptions: false,
  gdprChecked: undefined,
  splitTestId: null,
  isPopup: false,
  shopifyMarkets: false,
};

export type TextTransform = 'capitalize' | 'uppercase' | 'lowercase' | 'none';

export type ImageType = {
  src: string;
  key?: string;
  storage?: 'image' | 'S3' | 'icon' | 'Shopify';
  color?: string;
  sm?: {
    src: string;
    key: string;
  };
  md?: {
    src: string;
    key: string;
  };
  lg?: {
    src: string;
    key: string;
  };
  metadata?: {
    alt: string;
    originalHeight: number;
    originalWidth: number;
  };
};

export type IconsPackType = 'fc' | 'fa' | 'ri' | 'wi';

export type QuestionStylesType = {
  gdprConfirmation?: boolean;
  gdprRequired?: boolean;
  gdprText?: string;
  infoBtnTitle?: string;
  validations?: {
    extensions: 'doc' | 'image' | 'video';
    multiple: boolean;
    videoMaxSize: number;
  };
  limitPlaceholder?: string;
  layout?: string;
  imageSize?: string;
  imageWidth?: number;
  imageShape?: string;
  removeImageBorder?: boolean;
  showTitleAsButton?: boolean;
  textAlignment?: string;
  size?: string;
  contentImg?: ImageType;
  contentImgPosition?: string;
  contentImgWidth?: string;
  overlayColor?: string;
  overlayOpacity?: string;
  contentWidth?: string;
  placeholder?: string;
  emailValidation?: boolean;
  answersLimit?: number;
  phoneValidation?: boolean;
  numberValidation?: boolean;
  slideshow?: SlideshowSettings;
  rangeSliderWidthContainer: string;
  typeRangeSlider: string;
  rangeSliderHeightContainer: string;
  rangeSliderWidthBorder: string;
  scaleSliderHeightContainer: string;
  enableAnswer: boolean;
};

export type QuestionAnswer = {
  points: number | null;
  order: number;
  id: number;
  name: string;
  image: ImageType | null;
  nextQuestion?: number;
  redirectUrl?: string;
  productsQuantities: Record<string, string>;
};

export type Question = {
  id: number;
  name: string;
  type: string;
  description: string;
  answers: QuestionAnswer[];
  order: number;
  multiSelection: boolean;
  allowSkip: boolean;
  // eslint-disable-next-line
  meta: Record<string, any>;
  styles?: QuestionStylesType;
  nextQuestion?: number;
  nameAlias?: string;
  tooltip?: Record<string, string>;
};

export type SlideshowSettings = {
  enableSlideshow: boolean;
  slidesPerRow: number;
  spaceBetween: number;
};

export type AnswersStyles = {
  textColor: string;
  mainColor: string;
  borderRadius: string;
  borderColor: string;
  disableTransparentBackground: boolean;
  heading: BasicTextSettings;
  description: BasicTextSettings;
};

export type Events = {
  [index: string]: {
    googleAds?: string;
    facebookPixel?: string;
  };
};

export type BasicTextSettings = {
  textColor?: string;
  fontSize?: string;
  fontWeight?: number;
  fontStyle?: 'normal' | 'italic';
  letterSpacing?: number;
  mobile?: BasicTextSettings;
};

export type TooltipStyles = {
  icon: {
    backgroundColor: string;
    textColor: string;
    position: 'top' | 'bottom';
  };
  heading: BasicTextSettings & {
    textAlignment: string;
  };
  description: BasicTextSettings & {
    textAlignment: string;
  };
};

export type QuizStyles = {
  questions: {
    textAlignment: string;
    backgroundColor: string;
    prevBtnLabel: string;
    nextBtnLabel: string;
    skipBtnLabel: string;
    navigationLabel: string;
    answers: AnswersStyles;
    subheading: BasicTextSettings & {
      position: string;
    };
    heading: BasicTextSettings;
    description: BasicTextSettings;
    tooltip: TooltipStyles;
    swapHeadingAndSubheading: boolean;
    bottomText: BasicTextSettings;
    noOptionsMessage?: string;
  };
  subscribe: {
    phonePlaceholder: string;
    capturePhone: boolean;
    phoneErrorMessage: string;
    emptyFieldMessage: string;
    numberErrorMessage: string;
    gdprRequired: boolean;
    gdprText: string;
    description: string;
    subheading: string;
    successSubheading: string;
    successHeading: string;
    errorMessage: string;
    confirmBtnLabel: string;
    seeResultsBtnLabel: string;
    declineBtnLabel: string;
    placeholder: string;
    textAlignment: string;
    backgroundColor: string;
    contentImg: ImageType | null;
    contentImgPosition: string;
    contentImgWidth: string;
    overlayColor: string;
    overlayOpacity: string;
    subheadingStyles: BasicTextSettings;
    heading: BasicTextSettings;
    enableBackButton: boolean;
    addPhoneMask: boolean;
    phoneMaskDefaultCountry: string;
    input: BasicTextSettings & {
      borderColor: string;
      borderRadius: number;
    };
    decline: BasicTextSettings & {
      hidden: boolean;
    };
  };
  results: {
    productFullWidth: boolean;
    showVariantsAsBoxes: boolean;
    btnFullWidth: boolean;
    hideBundleBtn: boolean;
    hideAddBtn: boolean;
    maxWidth: number;
    textAlignment: string;
    backgroundColor: string;
    loadingText: string;
    addBtnLabel: string;
    soldBtnLabel: string;
    retakeBtnLabel: string;
    articleReadMoreLabel: string;
    addAllBtnLabel: string;
    successMessage: string;
    viewBtnLabel: string;
    viewCartBtnLabel: string;
    showProductsVendor: string;
    showProductsType: boolean;
    showMoneySaved: string;
    moneySavedLabel: string;
    popupHeading: string;
    popupSubscriptionLabel: string;
    popupCheckoutBtnLabel: string;
    showQuantityPicker: string;
    showVariantNameOnly: string;
    showProductTitleOnly: boolean;
    showVariantsAsProducts: string;
    showFeaturedImage: boolean;
    showSecondImage: boolean;
    limitProductDescription: boolean;
    showAllProductImages: boolean;
    hideRetakeButton: boolean;
    soldOutMessageLabel: string;
    showInStockProductsOnly: boolean;
    predefinedProductsQuantity: boolean;
    ignoreStoresCurrencyFormat: boolean;
    rechargeUpgraded: boolean;
    usePlanNameAsFrequency: boolean;
    addUniqueProductsOnly: boolean;
    rechargeOneTimeLabel: string;
    rechargeSubscribeLabel: string;
    rechargeDeliveryLabel: string;
    translations: TranslationsType;
    hideVariants: boolean;
    hideLinks: boolean;
    showDiscount: boolean;
    subheading: BasicTextSettings;
    heading: BasicTextSettings;
    description: BasicTextSettings;
    vendor: BasicTextSettings;
    title: BasicTextSettings;
    productDescription: BasicTextSettings;
    variant: BasicTextSettings;
    price: BasicTextSettings & {
      hidden: boolean;
    };
    itemsPerRow: string;
    imageHeight: string;
    imageWidth: string;
    resultsDiscountTitle: string;
  };
  general: {
    minHeight: string;
    addToCart: string;
    showProductDescription: string;
    addToCartEnabled: 'true' | 'false';
    openProductInNewTab: boolean;
    scrollToTop: string;
    scrollOffset: number;
    alwaysShowDiscount: boolean;
    showDiscountResultsOnly: boolean;
    borderWidth: number;
    hideWatermark: boolean;
    disableAutoChangeQuestion: boolean;
    disableAddAllToCartRedirect: boolean;
    disableOrderProperties: boolean;
    disableSuccessPopup: boolean;
    immediateUrlRedirect: boolean;
    customCss: string;
  };
  intro: {
    isDisabled: boolean;
    contentAlignment: string;
    imageAlignment: string;
    imageWidth: string;
    heading: BasicTextSettings;
    description: BasicTextSettings;
    button: BasicTextSettings & {
      style: 'fill' | 'outline';
      size: 'small' | 'medium' | 'large';
      mainColor: string;
      borderRadius: string;
      borderColor: string;
      textTransform: TextTransform;
    };
    customJs: string;
  };
  resultsLayouts?: {
    layoutId: string;
    layouts?: {
      // eslint-disable-next-line
      [key: string]: any;
    };
  };
};

export type ProgressBarStyles = {
  subheadingPosition: string;
  subheadingFontSize: string;
  subheadingFontWeight: number;
  subheadingColor: string;
  subheadingLetterSpacing: number;
  headingFont: string;
  backgroundColor: string;
  navigationLabel: string;
  fillColor: string;
  textColor: string;
  fontSize: string;
  fontWeight: number;
  hideText: boolean | undefined;
  maxWidth: number;
  size: number;
};

export type BarViewProps = {
  fontStyles: ProgressBarStyles;
  current: string | number;
  total: number;
  progress: number;
  animation?: string;
};

export type ProgressBarType = {
  layout: string;
  questionsPerStep: number;
  numberOfSteps: number;
  styles: {
    backgroundColor: string;
    fillColor: string;
    textColor: string;
    fontSize: string;
    fontWeight: number;
    hideText: boolean;
    maxWidth: number;
    size: number;
  };
} | null;

export type QuizFeatureVersions = {
  emailCapture?: number;
  inputForm?: number;
  personalization?: number;
  resultAIRecommended?: boolean;
  resultAiDefaultLayout?: boolean;
  resultProductSectionV2?: boolean;
  resultCustomSections?: number;
  onboard?: number;
  layoutVersion?: number;
};

export type Quiz = {
  id: number;
  quizKey: string;
  title: {
    text: null | string;
    fontSize: string;
    fontFamily: string;
    color: string;
  };
  description: {
    text: null | string;
    fontSize: string;
    fontFamily: string;
    color: string;
  };
  button: BasicTextSettings & {
    fontFamily: string;
    mainColor: string;
    text: string | null;
    style: 'fill' | 'outline';
    borderRadius: string;
    size: 'small' | 'medium' | 'large';
    borderColor: string;
    textTransform: TextTransform;
  };
  background: {
    backgroundColor: string;
    image: ImageType | null;
    mobileImage: ImageType | null;
    overlayColor: string;
    overlayOpacity: number;
    type: 'image' | 'solid';
  };
  questionBackground: {
    backgroundColor: string;
  };
  questions: Question[];
  collectEmail: boolean;
  discountTitle: string | null;
  discountCode: string | null;
  contentImage: ImageType | null;
  styles: QuizStyles;
  conditionalLogic: boolean;
  events: Events;
  rulesLogic: string;
  recommendedProductsLimit: null | number;
  gdprConfirmation: boolean;
  personalization: Record<string, string>;
  klavijoList: string | null;
  progressBar: ProgressBarType;
  contentBlocks?: {
    // eslint-disable-next-line
    [key: string]: any;
  };
  settings: {
    emailVerification?: boolean;
    questionAnimation?: string;
    resultsAnimation?: string;
    disableQuestionNameValidation?: boolean;
    showFrequencySelectBox?: boolean;
    featuresVersions?: QuizFeatureVersions;
    discount?: {
      showOnResults?: boolean;
      showBeforeResults?: boolean;
    };
  };
  url?: string;
};

export type Data = {
  quiz: Quiz;
  featuresList?: string[];
  hideWatermark?: boolean;
};

export type State = {
  quiz: Quiz;
  step: string;
  questionNumber: number;
  chosenAnswers: {
    [key: string]: number[];
  };
  quizKey: string;
  featuresList: string[];
  inputValues: {
    [key: string]: string;
  };
  inputErrors: {
    [key: string]: string;
  };
  recharge: boolean;
  redirectUrl: string;
  quizStarted: boolean;
  questionsPath: number[];
  moneyFormat: string;
  predefinedQuantities: Record<string, string>;
  predefinedValues: Record<string, string>;
  personalizationSection: boolean;
  nativeShopifySubscriptions: boolean;
  retakeQuiz: boolean;
  gdprChecked: boolean | undefined;
  splitTestId: number | null;
  isPopup: boolean;
  shopifyMarkets: boolean;
  hideWatermark?: boolean;
};

export type Action = {
  type: string;
  // eslint-disable-next-line
  payload: any;
};

export type AppType = {
  index: number;
  children: ReactNode;
  // eslint-disable-next-line
  element: any;
  isPopup?: boolean;
};

export type SubscribeType = {
  quiz: Quiz;
  skip: () => void;
  setEmail: (value: string) => void;
  setPhone: (value: string) => void;
  acceptGdpr: boolean;
  setAcceptGdpr: () => void;
};

export type Styles = {
  fieldTextAlignment?: string;
  buttonSize?: 'medium' | 'small' | 'large' | 'fullwidth';
  contactFormErrorMessages?: Record<string, string>;
  productFullWidth?: boolean;
  usePlanNameAsFrequency?: boolean;
  addUniqueProductsOnly?: boolean;
  gdprRequired?: boolean;
  gdprConfirmation?: boolean;
  showProductDescription?: boolean;
  limitProductDescription?: boolean;
  hideAddBtn?: boolean;
  soldBtnLabel?: string;
  addBtnLabel?: string;
  showQuantityPicker?: boolean;
  moneySavedLabel?: string;
  showMoneySaved?: boolean;
  showProductsType?: boolean;
  showProductsVendor?: boolean;
  hiddenPrice?: boolean;
  ignoreStoresCurrencyFormat?: boolean;
  showProductTitleOnly?: boolean;
  showVariantNameOnly?: boolean;
  hideLinks?: boolean;
  showFeaturedImage?: boolean;
  showSecondImage?: boolean;
  placeholder?: string;
  imageWidth?: string;
  declineLetterSpacing?: number;
  bodyMobile?: BasicTextSettings;
  bodyLetterSpacing?: number;
  inputLetterSpacing?: number;
  showVariantsAsBoxes?: boolean;
  btnFullWidth?: boolean;
  vendorFontWeight?: number;
  vendorFontStyle?: 'normal' | 'italic';
  vendorFontSize?: string;
  vendorColor?: string;
  vendorLetterSpacing?: number;
  vendorMobile?: BasicTextSettings;
  declineFontWeight?: number;
  inputFontWeight?: number;
  bodyFontSize?: string;
  bodyFontWeight?: number;
  buttonFontWeight: number;
  buttonLetterSpacing: number;
  headingFont: string;
  headingColor: string;
  headingFontWeight?: number;
  headingFontSize?: string;
  headingLetterSpacing?: number;
  headingMobile?: BasicTextSettings;
  bodyFont: string;
  bodyColor: string;
  buttonFontSize?: string;
  buttonStyle: 'fill' | 'outline';
  buttonFont: string;
  buttonMainColor: string;
  buttonTextColor?: string;
  buttonBorderRadius: string;
  buttonBorderColor: string;
  buttonTextTransform: TextTransform;
  answersMainColor?: string;
  answersTextColor?: string;
  answersBorderRadius?: string;
  answersBorderColor?: string;
  titleLetterSpacing?: number;
  titleFontWeight?: number;
  titleFontStyle?: string;
  titleFontSize?: string;
  titleColor?: string;
  titleMobile?: BasicTextSettings;
  productDescriptionFontSize?: string;
  productDescriptionColor?: string;
  productDescriptionLetterSpacing?: number;
  productDescriptionFontWeight?: number;
  productDescriptionFontStyle?: string;
  productDescriptionMobile?: BasicTextSettings;
  priceFontSize?: string;
  priceColor?: string;
  priceFontWeight?: number;
  priceFontStyle?: string;
  priceLetterSpacing?: number;
  priceMobile?: BasicTextSettings;
  itemsPerRow?: string;
  textAlignment?: string;
  imageHeight?: string;
  inputColor?: string;
  inputFontSize?: string;
  subheadingColor?: string;
  subheadingFontSize?: string;
  subheadingLetterSpacing?: number;
  subheadingFontWeight?: number;
  subheadingMobile?: BasicTextSettings;
  declineColor?: string;
  declineFontSize?: string;
  contentImg?: ImageType | null;
  contentImgPosition?: string;
  backgroundColor?: string;
  overlayColor?: string;
  overlayOpacity?: string;
  showAllProductImages?: boolean;
  borderWidth?: number;
  inputBorderColor?: string;
  inputBorderRadius?: number;
  answerHeadingTextColor?: string;
  answerHeadingFontSize?: string;
  answerHeadingFontWeight?: number;
  answerHeadingLetterSpacing?: number;
  answerHeadingMobile?: BasicTextSettings;
  answerDescriptionTextColor?: string;
  answerDescriptionFontSize?: string;
  answerDescriptionFontWeight?: number;
  answerDescriptionLetterSpacing?: number;
  answerDescriptionMobile?: BasicTextSettings;
  variantFontSize?: string;
  variantColor?: string;
  variantFontWeight?: number;
  variantFontStyle?: string;
  variantLetterSpacing?: number;
  variantMobile?: BasicTextSettings;
  hideVariants?: boolean;
  layout?: string;
  productShowTextType?: string;
  productButtonSize?: number;
  productButtonBorderRadius?: number;
  productImageRatio?: number;
  productImageBorderRadiusStyle?: number;
  productImageStyle?: 'thumbnails' | 'slideshow' | 'hidden';
  productImagesSliderOnMobile?: boolean;
  variantsViewStyle?: string;
  noOptionsMessage?: string;
};

export type AnswerData = {
  id: number;
  name: string;
  image: ImageType | null;
  description?: string | null;
  nextQuestion?: number;
  redirectUrl?: string | null;
  productsQuantities?: Record<string, string>;
  predefinedValues?: Record<string, string>;
};

export type AnswerType = {
  data: AnswerData;
  styles: Styles;
  goToQuestion: (index: number, nextQuestion?: number) => void;
  key?: string | number;
};

export type ImageProductType = {
  id: number;
  src: string;
  alt: string | undefined;
};

export type OptionType = {
  id: number;
  position: number;
  name: string;
  product_id: number;
  values: string[];
};

export type VariantType = {
  id: number;
  product_id: number;
  title: string;
  selected_options: VariantsOption[];
  price: string;
  compare_at_price: string | null;
  inventory_policy: string;
  inventory_quantity: number;
  is_sold_out?: symbol;
  duplicate_variant_id?: number | null;
  duplicate_variant_price?: string | null;
  shipping_interval_unit_type?: string | null;
  shipping_interval_frequency?: string | null;
  selling_plan?: string | null;
  selling_group?: string | null;
  image_id: number | null;
  quantity: number;
  market_price?: {
    price: string;
    compare_at_price: string | null;
    currency_code: string;
  };
  tracked: boolean;
  image?: ImageProductType | undefined;
};

export type TranslationsType = {
  day: string;
  days: string;
  week: string;
  weeks: string;
  month: string;
  months: string;
  year: string;
  years: string;
};

export type VariantSelectorType = {
  data: Pick<OptionType, 'name' | 'values'>;
  index: number;
  changeVariant(index: number, optionName: string, isEmbed?: boolean): void;
  styles: Styles;
  label?: string;
  chosenOption?: string;
  variants?: VariantType[];
  width: string;
  widgetSettings?: widgetSettingsType;
  subscription?: boolean;
  $colorsForBlock?: string;
  $showDefaultVariantSelector?: boolean;
};

export type FrequencySelectorType = {
  changeFrequency(val: string): void;
  styles: Styles;
  chosenOption?: string;
  width: string;
  translations: TranslationsType;
  sellingPlans: SellingPlanType[];
  name: string;
  $colorsForBlock?: string;
};

export type SingleProductType = {
  id: number;
  handle: string;
  title: string;
  image: ImageProductType;
  images: ImageProductType[];
  variants: VariantType[];
  options: OptionType[];
  body_html: string;
  vendor: string;
  chosenVariant?: VariantType;
  product_type: string;
  tags: string;
};

export type PopupProductType = {
  id: number;
  handle: string;
  title: string;
  image: ImageProductType;
  images: ImageProductType[];
  variants: VariantType[];
  options: OptionType[];
  body_html: string;
  vendor: string;
  chosenVariant: VariantType;
  sellingPlan?: SellingPlanType;
};

export type Subscription = {
  discount_percentage: number;
  duplicate_to_price: {
    [key: string]: string;
  };
  id: number;
  select_subscription_first: boolean;
  shipping_interval_frequency: string[];
  shipping_interval_unit_type: string;
  subscription_only: boolean;
  variant_to_duplicate: {
    [key: string]: string;
  };
  variant_to_price: {
    [key: string]: string;
  };
  label_onetime?: string;
  label_autodeliver?: string;
  label_deliver_every?: string;
  shipping_interval_unit_type_translated?: string;
};

export type ProductType = {
  product: SingleProductType;
  index: number;
  variant?: VariantType;
  predefinedQuantity: number;
  $colorText?: string;
  $showDefaultVariantSelector?: boolean;
  variantAsProduct?: boolean;
};

export type SubscriptionProductType = {
  product: SingleProductType;
  index: number;
  variant?: VariantType;
  predefinedQuantity: number;
  requiresSellingPlan: boolean;
  initialVariantsSubscriptions: SellingPlansType[];
  $colorText?: string;
  $showDefaultVariantSelector?: boolean;
  variantAsProduct?: boolean;
};

export type ResultType = {
  products: SingleProductType[];
  ruleset: {
    id?: number;
    title: string;
    description: string | null;
    subtitle: string | null;
    additional: {
      btnTitle?: string;
      btnUrl?: string;
      contentImg?: ImageType;
      contentImgPosition?: string;
      contentImgWidth?: string;
      overlayColor?: string;
      overlayOpacity?: string;
    };
    type: string;
    redirectUrl: string;
    footerText: string;
    productsQuantities: Record<string, string>;
    score?: number | null;
    // eslint-disable-next-line
    contentBlocks?: { [key: string]: any };
    articles?: {
      blogId: number;
      articleId: number;
    }[];
    settings?: {
      enableAITextAnimation?: boolean;
      enableAITextGenerate?: boolean;
      productsCount?: number;
    };
    productIds?: Record<string, number>;
    dynamicProductsHolder?: {
      collection?: {
        id: number;
        title: string;
      };
      tags?: string[];
      limit?: number;
      condition?: 'AND' | 'OR';
    };
  };
  resultPageId?: number;
  loaded: boolean;
  articles?: Article[];
  aiAutoGeneratedDescription?: string;
};

type SelectedAnswer = {
  id?: number;
  value: string;
};

export type UserAnswer = {
  questionId: number;
  questionTitle: string;
  questionOrder: number;
  selectedAnswers: SelectedAnswer[];
};

export type FetchResultData = {
  quizKey: string;
  answers: {};
  chosenAnswers: UserAnswer[];
  userData: {
    [key: string]: string | boolean | null;
  };
  currency: string;
  redirectResult: boolean;
  splitTestId: number | null;
  ignoreCompletion?: boolean;
  countryCode: string;
};

export type Heading = {
  fontSize?: string;
  fontFamily?: string;
  fontWeight?: number;
  fontStyle?: string;
  maxWidth?: number;
  margin?: string;
  lineHeight?: string;
  textTransform?: string;
  letterSpacing?: number;
  textAlignment?: string;
  mobile?: BasicTextSettings;
};

export type Button = {
  size?: 'small' | 'medium' | 'large' | 'fullwidth';
  kind?: 'fill' | 'outline';
  fontStyle?: string;
  fontSize?: string;
  fontFamily?: string;
  textColor?: string;
  mainColor?: string;
  minWidth?: string;
  maxWidth?: string;
  width?: string;
  borderRadius?: string;
  active?: boolean;
  margin?: string;
  borderColor?: string;
  height?: string;
  letterSpacing?: number;
  fontWeight?: number;
  textTransform?: TextTransform;
  borderWidth?: number;
  fullWidth?: boolean;
  mobile?: BasicTextSettings;
};

export type Overlay = {
  overlayColor: string;
  overlayOpacity: number;
};

export type InputType = {
  question: Question;
  styles: Styles;
  goToQuestion: (index: number, nextQuestion?: number) => void;
};

export type ContactFieldType = {
  id: string;
  name: string;
  placeholder: string;
  required: boolean;
  type?: 'text' | 'textarea' | 'date';
  nameAlias?: string;
  emailVerification?: boolean;
  addPhoneMask?: boolean;
  phoneMaskDefaultCountry?: string;
};

export type ContactFormType = {
  acceptGdpr?: boolean;
  // eslint-disable-next-line
  gdprCheckboxComponent?: any;
  styles: Styles;
  goToQuestion: (index: number, nextQuestion?: number) => void;
};

export type ContactFormFieldType = {
  styles: Styles;
  field: ContactFieldType;
  error: string;
  onKeyPress?(e: React.KeyboardEvent<HTMLInputElement>): void;
};

export type sellingPlanType = {
  selling_plan_id: number;
  order_interval_frequency: string;
  order_interval_unit_type: string;
  price_adjustments_value: number;
  price_adjustments_value_type: string;
};

export type sellingPlanGroupType = {
  selling_plan_group_id: string;
  selling_plans: sellingPlanType[];
};

export type subscriptionProductType = {
  selling_plan_groups: sellingPlanGroupType[];
  subscription_options: {
    storefront_purchase_options: string;
  };
  variants: [
    {
      id: number;
      prices: {
        discounted_price: string;
      };
    },
  ];
};

export type subscriptionProductWrapperType = {
  [key: string]: subscriptionProductType;
};

export type widgetSettingsType = {
  onetime_message: string;
  select_subscription_first: string;
  subscribe_message: string;
  subscribe_without_discount_message: string;
  translations: string;
  widget_deliver_every: string;
};

export type newSubscriptionsType = {
  products: subscriptionProductWrapperType[];
  widget_settings: widgetSettingsType;
} | null;

export type PricingPolicy = {
  adjustmentType: 'PERCENTAGE' | 'FIXED_AMOUNT' | 'PRICE';
  adjustmentValue: {
    amount?: string;
    percentage?: number;
    currencyCode?: string;
  };
};

export type SellingPlanType = {
  id: number;
  name: string;
  options: string[];
  billingPolicy: {
    interval: string;
  };
  pricingPolicies: PricingPolicy[];
};

export type SellingPlansType = {
  id: number;
  title: string;
  requiresSellingPlan?: boolean;
  sellingPlanGroupsCount: {
    count: number;
    precision: 'AT_LEAST' | 'EXACT';
  };
  sellingPlanGroups: SellingPlanType[][];
};

export type NavigationType = {
  goToQuestion: (
    index: number,
    nextQuestion?: number,
    skipped?: boolean,
    back?: boolean,
  ) => void;
  fontStyles: Styles;
  acceptGdpr: boolean;
};

export type QuestionContentType = {
  goToQuestion: (
    index: number,
    nextQuestion?: number,
    skipped?: boolean,
    back?: boolean,
  ) => void;
  skipQuestion: () => void;
  // eslint-disable-next-line
  answerItems: any;
  error: boolean;
};

export type TooltipType = {
  tooltip: Record<string, string>;
  styles: TooltipStyles;
  fontStyles: Styles;
  aosDelay: string;
  animation: string;
};

export type ResponsiveImageComponentType = {
  image: ImageType;
  alt: string;
  mobileImage?: ImageType | null;
  imgTag?: boolean;
  mobileImageDisabled?: boolean;
};

export type ProductImagesType = {
  product: SingleProductType;
  chosenVariant: VariantType;
  fontStyles: Styles;
  productUrl: string;
  linkTarget: string;
};

export type FileStatusType = 'FAILED' | 'PROCESSING' | 'READY' | 'UPLOADED';

export type FileErrorType = {
  code: string;
  details: string;
  message: string;
};

export type FilesResponseType = {
  data: {
    files: ShopifyFileType[];
  };
};

export type ShopifyFileType = {
  id: string;
  fileStatus: FileStatusType;
  mimeType: string;
  url?: string;
  image?: {
    id: string;
    url: string;
    altText: string;
    width: number;
    height: number;
  };
  originalSource?: {
    url: string;
    width: number;
    height: number;
    format: string;
    mimeType: string;
  };
};

export type CreateFileResponseType = {
  data: {
    files: ShopifyFileType[];
    userErrors: FileErrorType[];
  };
};

export type OffsetProductArrayType = {
  key: string;
  value: number;
};

export type Answer = {
  questionId: number;
  questionTitle: string;
  selectedAnswers: { value: string; id?: number }[];
};

export type InputValuesWithURL = {
  [key: number]: { [key: string]: string };
};

export type ChosenAnswersURL = {
  [key: number]: number[];
};

export type Override<T, U extends { [key in keyof T]?: U[key] }> = Omit<
  T,
  keyof U
> &
  U;
